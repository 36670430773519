async function getUserRest(username, token) {
  const url = `/api/v1/user/?q=(username:${username})`;
  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    // mode: "no-cors"
  };
  // console.log(params)
  // console.log(token)
  return fetch(url, params).then(res => res.json());
}

export { getUserRest };
