import { Alert, Button, Grid, IconButton, Paper, TextField, Typography } from "@mui/material";
import { useContext, useState } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useForm } from "react-hook-form";
import UserContext from "./UserContext";
import { Navigate } from "react-router-dom";
import { loginRest } from "../api/Login";
import { getUserRest } from "../api/User";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const { user, setUser, token, setToken } = useContext(UserContext);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  // Login and store token in UserContext
  async function handleLogin(data) {
    const res = await loginRest(data.username, data.password);
    if ("access_token" in res) {
      setToken(res.access_token);

      const uid = await getUserRest(data.username, res.access_token);
      console.log(uid);
      setUser(uid);

      setLoginError(false);
    } else {
      setLoginError(true);
    }
  }

  return (
    <>
      {user ? (
        <Navigate to="/" />
      ) : (
        <Grid container justifyContent="center">
          <Paper sx={{ width: 400, padding: 5 }} elevation={3}>
            <Grid container direction="column" alignItems="center" gap={2}>
              <Typography variant="h6">请登录</Typography>
              <TextField
                required
                label="用户名"
                fullWidth
                {...register("username", { required: true })}
                error={errors.username && true}
                helperText={errors.username && "请输入用户名"}
              />
              <TextField
                required
                label="密码"
                type={showPassword ? "text" : "password"}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...register("password", { required: true })}
                error={errors.password && true}
                helperText={errors.password && "请输入密码"}
              />
              {loginError ? (
                <Alert severity="error" sx={{ width: "100%", boxSizing: "border-box" }}>
                  登录失败
                </Alert>
              ) : null}
              <Button variant="contained" fullWidth onClick={handleSubmit(handleLogin)}>
                登录
              </Button>
            </Grid>
          </Paper>
        </Grid>
      )}
    </>
  );
};

export default Login;
