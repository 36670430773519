// import 'bootstrap/dist/css/bootstrap.min.css';
// import './index.css';
import React from "react";
import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import { DetailReport } from "./components/detailReport";
import Invoice from "./components/invoice";
import Login from "./components/Login";
import useToken from "./components/useToken";
import UserContext from "./components/UserContext";
import Header from "./components/Header";
import useUser from "./components/useUser";
import AddEditInventory from "./components/AddEditInventory";
import ListInventory from "./components/ListInventory";
import ShipInventory from "./components/ShipInventory";
import Tracking from "./components/Tracking";
import ListOrder from "./components/ListOrder";
import Sidebar from "./components/Sidebar";
import { Container, Grid, Toolbar } from "@mui/material";
import InventoryMonthlyDetail from "./components/InventoryReport";

function App() {
  const [user, setUser] = useUser();
  const [token, setToken] = useToken();

  return (
    <>
      <UserContext.Provider value={{ user, setUser, token, setToken }}>
        {user ? (
          <>
            <BrowserRouter>
            <Header />
            
            <Sidebar />
            <Grid container sx={{paddingLeft: '160px'}}>
              <Grid container direction='column' sx={{padding: '20px'}}>
              <Routes>
                <Route path="/home" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/inventory/add/:action" element={<AddEditInventory />} />
                <Route path="/inventory/edit/:id" element={<AddEditInventory />} />
                <Route path="/inventory/list" element={<ListInventory />} />
                <Route path="/inventory/detailreport" element={<DetailReport />} />
                <Route path="/order/list" element={<ListOrder />} />
                <Route path="/shipment/:id" element={<Tracking />} />
                <Route path="/" element={<Home />} />
                <Route path="/report/inventory/monthlydetail" element={<InventoryMonthlyDetail />} />
              </Routes>
              </Grid>
            </Grid>
            </BrowserRouter>
          </>
        ) : (
          <Login />
        )}
      </UserContext.Provider>
      {/* <InventoryList></InventoryList> */}
    </>
  );
}

export default App;
