import { Container, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getRest } from "../api/CRUD";
import UserContext from "./UserContext";
import '../css/print.css';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: "1px solid",
}));

const LineItems = props => (
  <TableContainer {...props}>
    <Table>
      <TableHead>
        <TableRow>
          <StyledTableCell>产品名称</StyledTableCell>
          <StyledTableCell>数量</StyledTableCell>
          <StyledTableCell>备注</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props &&
          props?.children.map(row => (
            <TableRow>
              <StyledTableCell>{row.product.name}</StyledTableCell>
              <StyledTableCell>{row.quantity}</StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  </TableContainer>
);

const Tracking = () => {
  const business_entity = "浙江道和机械股份有限公司";
  const { token } = useContext(UserContext);
  const [shipment, setShipment] = useState();
  const [inventory, setInventory] = useState();
  const { id } = useParams();
  const items = [{ product: "aaa", quantity: 10 }];

  // fetch shipment
  useEffect(() => {
    async function fetchShipment() {
      const res = await getRest("shipment", id, token);
      console.log(res);
      setShipment(res.result);
    }
    fetchShipment();
  }, [id, token]);

  // fetch inventory
  useEffect(() => {
    async function fetchInventory() {
      if (shipment) {
        const order_id = shipment?.order[0].id
        const order = await getRest('order', order_id, token);
        const inv = order?.result.inventory
        const inv_detail = await Promise.all(inv.map(async (v) => {
          const inv_id = v.id
          const detail = await getRest('inventory', inv_id, token)
          return (detail.result)
        }))
        console.log(inv_detail)
        setInventory(inv_detail);
      }
    }
    fetchInventory();
  }, [shipment])

  return (
    <Container>
      <Typography variant="h5">发货单</Typography>
      <Paper sx={{ margin: 2 }} className='printable'>
        <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ padding: 2 }}>
          <Typography variant="h5">嘉兴翱翔通用设备送货单</Typography>
        </Grid>
        <Grid container direction="column" justifyContent="left" width="500" sx={{ padding: 2 }}>
          <Grid container direction="row">
            <Grid item xs={2}>
              <Typography>收货单位: </Typography>
            </Grid>
            <Grid item>
              <Typography>{business_entity}</Typography>
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={2}>
              <Typography>送货单号: </Typography>
            </Grid>
            <Grid item>
              <Typography>{shipment?.tracking_id}</Typography>
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item xs={2}>
              <Typography>送货日期: </Typography>
            </Grid>
            <Grid item>
              <Typography>{new Date().toISOString().split("T")[0]}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>{inventory ? <LineItems sx={{ padding: 2 }}>{inventory}</LineItems> : null}</Grid>
        <Grid container direction="row" sx={{ mt: 1, padding: 2 }}>
          <Grid item xs={6}>
            <Typography>送货单位签字:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>收货单位签字:</Typography>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default Tracking;
