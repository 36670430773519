
async function getDict(resource, token) {
  const url = `/api/v1/${resource}/`;
  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const data = await fetch(url, params).then(res => res.json());
  return data.result.map((v, i) => Object.assign(v, {pk: data.ids[i]}))
}

export default getDict;
