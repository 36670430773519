import { Button, Dialog, DialogActions, DialogTitle, Grid, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridRow, GridToolbar } from "@mui/x-data-grid";
import { useContext, useEffect, useState } from "react";
import { addRest, deleteRest, listRest, updateRest } from "../api/CRUD";
import UserContext from "./UserContext";
import DeleteIcon from "@mui/icons-material/Delete";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PrintIcon from "@mui/icons-material/Print";
import getDict from "../api/Dict";

const DeleteOrderDialog = props => {
  const { id, title, open, onClose, onSubmit = null } = props;
  return (
    // <form onSubmit={onSubmit}>
    <Dialog open={open || false} onClose={onClose} aria-labelledby="delete-dialog-title" aria-describedby="delete-dialog-description">
      <DialogTitle id="delete-dialog-title">{title + id}</DialogTitle>
      <DialogActions>
        <Button size="small" onClick={onClose}>
          取消
        </Button>
        <Button
          type="submit"
          size="small"
          variant="contained"
          onClick={() => {
            onSubmit && onSubmit();
            onClose();
          }}
        >
          确认
        </Button>
      </DialogActions>
    </Dialog>
    // </form>
  );
};

const ListOrder = () => {
  const { user, token } = useContext(UserContext);
  const [rows, setRows] = useState();
  const [openDeleteOrderDialog, setOpenDeleteOrderDialog] = useState();
  const [selectionModel, setSelectionModel] = useState([]);
  const [businessList, setBusinessList] = useState();

  async function fetchBusinessList() {
    const businessList = await getDict("businessentity", token);
    console.log(businessList);
    setBusinessList(businessList);
  }

  async function fetchOrder() {
    const rows = await listRest("order", token);
    rows.ids.map((id, idx) => {
      rows.result[idx]["id"] = id;
    });
    console.log(rows);
    setRows(rows.result);
  }

  function toggleDialog(id, state, setState) {
    const newState = { ...state };
    newState[id] = !newState[id];
    setState(newState);
  }

  // Initialise onMount
  useEffect(() => {
    fetchBusinessList();
    fetchOrder();
  }, []);

  useEffect(() => {
    const newState = {};
    rows?.map(row => {
      newState[row.id] = false;
    });
    setOpenDeleteOrderDialog(newState);
  }, [rows]);

  const columns = [
    { field: "id", headerName: "订单号", valueGetter: params => params.row.id },
    { field: "client", headerName: "客户", type: "singleSelect", valueGetter: params => params.row.client.name },
    { field: "created_on", headerName: "创建时间", valueGetter: params => params.row.created_on.split("T")[0] },
    {
      field: "inventory",
      headerName: "相关进件单号",
      renderCell: params => (
        <Grid container>
          {params.row.inventory.map(line => (
            <Typography key={line.id}>{line.id}</Typography>
          ))}
        </Grid>
      ),
    },
    {
      field: "action",
      headerName: "动作",
      type: "actions",
      minWidth: 150,
      getActions: params => [
        <Tooltip title="删除">
          <IconButton
            label="删除"
            color="error"
            onClick={() => {
              toggleDialog(params.id, openDeleteOrderDialog, setOpenDeleteOrderDialog);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>,
        <Tooltip title="发货">
          <IconButton
            label="发货"
            disabled={params.row.shipment ? true : false}
            color="primary"
            onClick={async () => {
              const shipment = await addRest("shipment", { created_by: user, changed_by: user }, token);
              shipment.result["id"] = shipment.id;
              await updateRest("order", params.row.id, { shipment: shipment.id, changed_by: user }, token);
              setRows(prevRows => prevRows.map(row => (row.id === params.row.id ? { ...row, shipment: shipment.result } : row)));
            }}
          >
            <LocalShippingIcon />
          </IconButton>
        </Tooltip>,
        <Tooltip title="打印发货记录">
          <IconButton
            label="打印"
            disabled={!params.row.shipment ? true : false}
            color="secondary"
            href={"/shipment/" + params.row.shipment?.id}
            target="_blank"
          >
            <PrintIcon />
          </IconButton>
        </Tooltip>,
        <DeleteOrderDialog
          {...params}
          title="确认删除"
          open={openDeleteOrderDialog[params.id]}
          onClose={() => toggleDialog(params.id, openDeleteOrderDialog, setOpenDeleteOrderDialog)}
          onSubmit={async () => {
            const res = await deleteRest("order", params.row.id, token);
            setRows(prevRows => prevRows.filter(row => row.id !== params.row.id));
          }}
        />,
      ],
    },
    { field: "shipment", headerName: "发货记录", minWidth: 180, valueGetter: params => params.row.shipment?.tracking_id },
  ];

  return (
    <>
      <Typography variant="h5">{"订单明细"}</Typography>
      {rows && (
        <DataGrid
          autoHeight
          components={{
            Toolbar: GridToolbar,
          }}
          columns={columns}
          rows={rows}
          checkboxSelection={true}
          disableSelectionOnClick={true}
          onSelectionModelChange={newSelectionModel => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
        />
      )}
    </>
  );
};

export default ListOrder;
