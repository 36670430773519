import { Avatar, Button, IconButton, Toolbar, Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import { useContext } from "react";
import UserContext from "./UserContext";
import { useNavigate, Link } from "react-router-dom";

const Header = () => {
  const { token, setToken, user, setUser } = useContext(UserContext);
  // const navigate = useNavigate();

  function handleLogout() {
    setUser(null);
    setToken(null);
  }

  return (
    <AppBar sx={{ position: "relative", zIndex: theme => theme.zIndex.drawer + 1 }}>
      <Toolbar sx={{ justifyContent: "space-between" }} variant="dense">
        <Button variant="standard" component={Link} to="/">
          <Typography variant="h6">Home</Typography>
        </Button>
        <Avatar>
          {user ? (
            <IconButton onClick={handleLogout}>
              <LogoutIcon />
            </IconButton>
          ) : (
            <IconButton component="a" href="/login">
              <LoginIcon />
            </IconButton>
          )}
        </Avatar>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
