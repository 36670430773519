import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Select, MenuItem, Grid, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { styled } from "@mui/system";
import { listRest } from "../api/CRUD";
import UserContext from "./UserContext";
import getDict from "../api/Dict";
import { LocalizationProvider, MonthPicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: "1px solid",
}));

const DataTable = ({ records, labelColumns, listColumns }) => {
  if (records === undefined) return null;
  if (labelColumns === undefined) return null;
  if (listColumns === undefined) return null;
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>{listColumns ? listColumns.map(k => <StyledTableCell>{labelColumns[k]}</StyledTableCell>) : null}</TableRow>
        </TableHead>
        <TableBody>
          {records &&
            records.map(row => <TableRow>{listColumns ? listColumns.map(k => <StyledTableCell>{row[k]}</StyledTableCell>) : null}</TableRow>)}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const InventoryMonthlyDetail = () => {
  const { user, token } = useContext(UserContext);
  const [records, setRecords] = useState([]);
  const [labelColumns, setLabelColumns] = useState({});
  const [listColumns, setListColumns] = useState([]);
  const [businessList, setBusinessList] = useState();
  const [biz_id, setBizId] = useState();

  async function fetchReport(biz_id) {
    const lst = await listRest("report/inventory/monthlydetail", token, `(biz_id:${biz_id})`);
    setRecords(lst?.result);
    setLabelColumns(lst?.label_columns);
    setListColumns(lst?.list_columns);
    console.log(lst);
  }

  async function fetchBusinessList() {
    const businessList = await getDict("businessentity", token);
    setBusinessList(businessList);
    console.log(businessList);
  }

  useEffect(() => {
    fetchBusinessList();
  }, []);

  useEffect(() => {
    if (biz_id !== null && biz_id !== undefined) {
      fetchReport(biz_id);
    }
  }, [biz_id]);

  return (
    <>
      <Typography variant="h5">库存月报表</Typography>
      <Grid container>
        <TextField select size="small" margin="normal" label="企业" sx={{ minWidth: 200 }} onChange={e => setBizId(e.target.value)}>
          {businessList ? businessList.map(v => <MenuItem value={v.pk}>{v.name}</MenuItem>) : null}
        </TextField>
        
      </Grid>
      <DataTable records={records} labelColumns={labelColumns} listColumns={listColumns} />
    </>
  );
};

export default InventoryMonthlyDetail;
