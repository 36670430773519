async function loginRest(username, password, provider='db') {
  const url = "/api/v1/security/login";
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ username, password, provider }),
  };
  return fetch(url, params)
    .then(res => {
      if (!res.ok) {
        const err = Error(res.statusText);
        err.code = res.status;
        throw err;
      }
      return res.json();
    })
    .catch(err => {
      console.log(err);
      return err;
    });
}

export { loginRest };