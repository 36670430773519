import {useState} from "react";

const useToken = () => {
  function getToken() {
    return localStorage.getItem("token");
  }
  
  const [token, setToken] = useState(getToken());

  function saveToken(token) {
    token === null ? localStorage.removeItem('token') : localStorage.setItem("token", token);
    setToken(token);
  }

  return [token, saveToken];
};

export default useToken;
