import {useState} from "react";

const useUser = () => {
  function getUser() {
    return localStorage.getItem("user");
  }
  
  const [user, setUser] = useState(getUser());

  function saveUser(user) {
    user === null ? localStorage.removeItem('user') : localStorage.setItem("user", user);
    setUser(user);
  }

  return [user, saveUser];
};

export default useUser;
