import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import { useTable } from "react-table";
import styled from "styled-components";
import { useState, useEffect } from "react";
import { DateRangePicker } from "react-date-range";

const Styles = styled.div`
  table {
    font-size: 10px;
  }
`;

function Table({ columns, data }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <table {...getTableProps()} className="table">
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

function dateFormat(dt) {
  return dt.getFullYear() + "-" + (dt.getMonth() + 1) + "-" + dt.getDate();
}

function dateStrip(dtString) {
  const dt = new Date(dtString);
  return dt.getDate();
}

function DetailReport() {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  const [columns, setColumns] = useState([]);

  const today = new Date();
  const [month, setMonth] = useState(String(today.getMonth()));
  const [year, setYear] = useState(String(today.getFullYear()));

  const yearOption = Array(today.getFullYear() - 2021 + 1)
    .fill()
    .map((item, index) => 2021 + index);
  const monthOption = Array(12)
    .fill()
    .map((item, index) => index);

  useEffect(() => {
    setLoading(true);
    let dt = new Date(year, month);
    let start = new Date(dt);
    let end = new Date(dt);
    end.setMonth(end.getMonth() + 1);
    end.setDate(end.getDate() - 1);

    console.log(dateFormat(start));
    console.log(dateFormat(end));

    const url = `/api/v1/inventory/detailreport/?q=(start:'${dateFormat(
      start
    )}',end:'${dateFormat(end)}')`;
    console.log(url);

    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setData(data);
      })
      .catch(setError)
      .finally(() => {
        setLoading(false);
      });
  }, [year, month]);

  useEffect(() => {
    if ( data && Object.keys(data).length !== 0 ) {
      const columns_common = data.list_columns.filter(k => ["biz_label", "biz_name", "product_name", "action_label", "total"].includes(k))
        .map(k => ({
          accessor: k,
          Header: data.label_columns[k]
        }));
      const columns_dates = data.list_columns.filter(k => !["biz_label", "biz_name", "product_name", "action_label", "total"].includes(k))
      .map(k => ({
        accessor: k,
        Header: dateStrip(data.label_columns[k])
      }));
      setColumns(columns_common.concat(columns_dates));
    }
  }, [data]);
  

  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  };

  return (
    <Styles>
      <div className="container">
      <p className='h1'>月库存明细报表</p>
        <form>
          <div className="row">
            <div className="form-group col-xs-4 col-sm-3">
              <label className="form-label">请选择报表年份</label>
              <select
                className="form-control"
                defaultValue={year}
                onChange={(e) => {
                  setYear(e.target.value);
                }}
              >
                <option disabled hidden value="">
                  请选择年份
                </option>
                {yearOption.map((item) => (
                  <option key={item} value={item}>
                    {item + "年"}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group col-xs-4 col-sm-3">
              <label className="form-label">请选择报表月份</label>
              <select
                className="form-control"
                defaultValue={month}
                onChange={(e) => {
                  setMonth(e.target.value);
                }}
              >
                <option disabled hidden value="">
                  请选择月份
                </option>
                {monthOption.map((item) => (
                  <option key={item} value={item}>
                    {item + 1 + "月"}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </form>
      </div>

      <div className="container testimonial-group">
        <div className="row">
          {data && Object.keys(data).length !== 0 ? (
            <Table columns={columns} data={data.result} />
          ) : <div className='col-xs-6 col-sm-6'><p className='h3'>无库存记录</p></div>}
        </div>
      </div>
    </Styles>
  );
}

export { DetailReport };
