import { Button, Container, Grid, MenuItem, Snackbar, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import getDict from "../api/Dict";
import { addRest, getRest, updateRest } from "../api/CRUD";
import UserContext from "./UserContext";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { useForm, Controller } from "react-hook-form";
import { useParams } from "react-router-dom";

const AddEditInventory = () => {
  const { user, token } = useContext(UserContext);
  const { id } = useParams();
  const { action } = useParams();
  const [productList, setProductList] = useState();
  const [businessList, setBusinessList] = useState();
  const [defaultValues, setDefaultValues] = useState({
    business: "",
    product: "",
    quantity: "",
    date: new Date().toISOString().split("T")[0],
  });
  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitSuccessful },
  } = useForm({
    defaultValues: defaultValues,
  });

  async function handleAdd(data) {
    console.log(data);
    // create inventory record
    const inv = await addRest(
      "inventory",
      {
        action: action,
        business_entity: data.business,
        product: data.product,
        quantity: data.quantity,
        dt: data.date,
        created_by: user,
        changed_by: user,
      },
      token
    );
    console.log(inv);
    // setTimeout(() => {navigate('/')}, 2000)
    setTimeout(() => {
      reset();
    }, 2000);
  }

  useEffect(() => {
    async function fetchInventory() {
      const inventory = await getRest("inventory", id, token);
      console.log(inventory);
      const inv = {
        business: inventory.result.business_entity.id,
        product: inventory.result.product.id,
        quantity: inventory.result.quantity,
        date: inventory.result.dt,
      };
      setDefaultValues(inv);
      reset(inv);
    }
    if (id) {
      fetchInventory();
    }

    async function fetchProductList() {
      const productList = await getDict("product", token);
      console.log(productList);
      // setProductList(productList.filter(v => v.name === "电池盒整套"));
      setProductList(productList);
    }
    fetchProductList();

    async function fetchBusinessList() {
      const businessList = await getDict("businessentity", token);
      setBusinessList(businessList);
      console.log(businessList);
    }
    fetchBusinessList();
  }, [token, id]);

  return (
    <Container>
      <Grid container gap={2}>
        <Typography variant="h5">{action === undefined ? null : action === '1' ? "新产品" : "返工"}</Typography>
        <Typography variant="h5">{id ? "修改进件记录" : "进件"}</Typography>
        <Grid container gap={3}>
          <Controller
            name="business"
            control={control}
            rules={{ required: { value: true, message: "请选择企业" } }}
            render={({ field, fieldState: { error, invalid }, formState: { isSubmitSuccessful } }) => (
              <TextField
                disabled={isSubmitSuccessful}
                size="small"
                select
                label="企业"
                sx={{ width: 200 }}
                {...field}
                error={invalid}
                helperText={error?.message}
              >
                {businessList &&
                  businessList.map(v => (
                    <MenuItem key={v.pk} value={v.pk}>
                      {v.name}
                    </MenuItem>
                  ))}
              </TextField>
            )}
          />
          <Controller
            name="product"
            control={control}
            rules={{ required: { value: true, message: "请选择产品" } }}
            render={({ field, fieldState: { error, invalid }, formState: { isSubmitSuccessful } }) => (
              <TextField
                disabled={isSubmitSuccessful}
                size="small"
                select
                label="产品"
                defaultValue="1"
                sx={{ width: 200 }}
                {...field}
                error={invalid}
                helperText={error?.message}
              >
                {productList &&
                  productList.map(v => (
                    <MenuItem key={v.pk} value={v.pk}>
                      {v.name}
                    </MenuItem>
                  ))}
              </TextField>
            )}
          />
          <Controller
            name="quantity"
            control={control}
            rules={{ required: { value: true, message: "请输入数量" }, min: { value: 1, message: "请输入正确的数量" } }}
            render={({ field, fieldState: { error, invalid }, formState: { isSubmitSuccessful } }) => (
              <TextField
                disabled={isSubmitSuccessful}
                size="small"
                type="number"
                label="数量"
                sx={{ width: 200 }}
                {...field}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Controller
              name="date"
              control={control}
              rules={{ required: { value: true, message: "请选择日期" } }}
              render={({ field: { onChange, value }, fieldState: { error, invalid }, formState: { isSubmitSuccessful } }) => (
                <DatePicker
                  disabled={isSubmitSuccessful}
                  label="日期"
                  value={value}
                  onChange={dt => onChange(dt)}
                  renderInput={params => <TextField size="small" {...params} error={invalid} helperText={error?.message} />}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <form onSubmit={handleSubmit(handleAdd)}>
          <Grid container gap={1}>
            <Button variant="outlined" color="secondary" onClick={() => reset(defaultValues)}>
              重置
            </Button>
            <Button type="submit" variant="contained">
              提交
            </Button>
          </Grid>
          <Snackbar
            open={isSubmitSuccessful}
            autoHideDuration={6000}
            onClose={() => {
              return;
            }}
            message="成功"
          />
        </form>
      </Grid>
    </Container>
  );
};

export default AddEditInventory;
