async function getRest(resource, pk, token) {
  const url = `/api/v1/${resource}/${pk}`;
  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return await fetch(url, params).then(res => res.json());
}

async function listRest(resource, token, query) {
  const url = `/api/v1/${resource}/`;
  const urlQuery = `/api/v1/${resource}/?q=${query}`;
  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return await fetch(query ? urlQuery : url, params).then(res => res.json());
}

async function addRest(resource, data, token) {
  const url = `/api/v1/${resource}/`;
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };
  return await fetch(url, params).then(res => res.json());
}

async function updateRest(resource, pk, data, token) {
  const url = `/api/v1/${resource}/${pk}`;
  const params = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };
  return await fetch(url, params).then(res => res.json());
}

async function deleteRest(resource, pk, token) {
  const url = `/api/v1/${resource}/${pk}`;
  const params = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  return await fetch(url, params).then(res => res.json());
}

export { getRest, listRest, addRest, updateRest, deleteRest };