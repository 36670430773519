import { DataGrid, GridToolbar, zhCN, GridToolbarContainer } from "@mui/x-data-grid";
import { useContext, useEffect, useReducer, useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { addRest, listRest, updateRest, deleteRest, getRest } from "../api/CRUD";
import getDict from "../api/Dict";
import UserContext from "./UserContext";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { createTheme, ThemeProvider } from "@mui/system";
import Tracking from "./Tracking";
import { Controller, useForm } from "react-hook-form";

// const theme = createTheme(
//   {
//     palette: {
//       primary: { main: '#1976d2' },
//     },
//   },
//   zhCN
// )

const ListInventory = ({ name, filter }) => {
  const { user, token } = useContext(UserContext);
  const [rows, setRows] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState();
  const [openOrderDialog, setOpenOrderDialog] = useState();
  const [filterModel, setFilterModel] = useState({ items: [] });
  const [businessList, setBusinessList] = useState();
  const [selectionModel, setSelectionModel] = useState([]);
  const {
    handleSubmit,
    control,
    formState: { isValid },
    reset,
  } = useForm({
    mode: "all",
    defaultValues: {
      client: "",
    },
  });

  async function fetchList() {
    const lst = await listRest("inventory", token);
    const rows = lst.result.map((v, i) => {
      v["id"] = lst.ids[i];
      return v;
    });
    console.log(rows);
    setRows(rows);
  }

  async function fetchBusinessList() {
    const businessList = await getDict("businessentity", token);
    setBusinessList(businessList);
    console.log(businessList);
  }

  async function createOrder(client_id) {
    const order = await addRest("order", { client_id, created_by: user, changed_by: user }, token);
    return order;
  }

  async function handleShipment(id) {
    const shipment = await addRest("shipment", { created_by: user, changed_by: user }, token);
    shipment.result["id"] = shipment.id;
    console.log(shipment);
    await updateRest("inventory", id, { shipment: shipment.id, changed_by: user }, token);
    setRows(prevRows => prevRows.map(row => (row.id === id ? { ...row, shipment: shipment.result } : row)));
  }

  async function cancelShipment(id, shipment_id) {
    const inv = await getRest("inventory", id, token);
    await updateRest("inventory", id, { shipment: null, changed_by: user }, token);
    await deleteRest("shipment", inv.result.shipment.id, token);
    setRows(prevRows => prevRows.map(row => (row.id === id ? { ...row, shipment: null } : row)));
  }

  // onMount
  useEffect(() => {
    fetchBusinessList();
    fetchList();
  }, []);

  // init filterModel and dialog status
  useEffect(() => {
    setFilterModel(filter);
    setOpenDeleteDialog(new Array(rows.length).fill(false));
    setOpenOrderDialog(new Array(rows.length).fill(false));
  }, [rows]);

  const columns = [
    { field: "id", headerName: "id", valueGetter: params => params.row.id },
    {
      field: "business_entity",
      headerName: "企业",
      valueGetter: params => params.row.business_entity.name,
    },
    {
      field: "action",
      headerName: "库存操作",
      valueGetter: params => params.row.action.label,
    },
    { field: "product", headerName: "产品", valueGetter: params => params.row.product.name },
    { field: "quantity", headerName: "数量", type: "number" },
    { field: "dt", headerName: "日期", type: "date" },
    {
      field: "actions",
      headerName: "操作",
      headerAlign: "center",
      align: "center",
      width: 180,
      renderCell: params => (
        <Stack direction="row" spacing={1} justifyContent="center">
          <Tooltip title={params.row.order !== null ? "请先删除订单，再删除库存记录" : "删除"}>
            <span>
            <IconButton
              disabled={params.row.order !== null}
              aria-label="delete"
              onClick={() => {
                const newState = [...openDeleteDialog];
                newState[params.id] = true;
                setOpenDeleteDialog(newState);
              }}
            >
              <DeleteIcon />
            </IconButton>
            </span>
          </Tooltip>
          <Dialog
            open={openDeleteDialog[params.id] || false}
            onClose={() => {
              const newState = [...openDeleteDialog];
              newState[params.id] = false;
              setOpenDeleteDialog(newState);
            }}
            aria-labelledby="alert-delete-title"
            aria-describedby="alert-delete-description"
          >
            <DialogTitle id="alert-delete-title">确认删除</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-delete-description">请确认删除库存记录 {params.row.id} ？</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                onClick={() => {
                  const newState = [...openDeleteDialog];
                  newState[params.id] = false;
                  setOpenDeleteDialog(newState);
                }}
              >
                取消
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={async () => {
                  await deleteRest("inventory", params.row.id, token);
                  setRows(prevRows => prevRows.filter(row => row.id !== params.row.id));

                  const newState = [...openDeleteDialog];
                  newState[params.id] = false;
                  setOpenDeleteDialog(newState);
                }}
              >
                确认
              </Button>
            </DialogActions>
          </Dialog>
          <Tooltip title="修改">
          <IconButton id="edit" variant="outlined" color="primary" size="small" href={`/inventory/edit/${params.row.id}`}>
            <EditIcon />
          </IconButton>
          </Tooltip>

          <Button
            disabled={params.row.order !== null}
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              const newState = [...openOrderDialog];
              newState[params.id] = true;
              setOpenOrderDialog(newState);
            }}
          >
            下单
          </Button>
          <Dialog
            open={openOrderDialog[params.id] || false}
            onClose={() => {
              const newState = [...openOrderDialog];
              newState[params.id] = false;
              setOpenOrderDialog(newState);
            }}
            aria-labelledby={`alert-order-title`}
            aria-describedby={`alert-order-description`}
            fullWidth
          >
            <form
              onSubmit={handleSubmit(async data => {
                const order = await addRest("order", { client: data.client, created_by: user, changed_by: user }, token);
                order.result["id"] = order.id;
                setRows(prevRows => prevRows.map(row => (row.id === params.row.id ? { ...row, order: order.result } : row)));
                await updateRest("inventory", params.row.id, { order: order.id, changed_by: user }, token);
                reset();
              })}
            >
              <DialogTitle id={`alert-order-title`}>确认下单</DialogTitle>
              <DialogContent>
                <Controller
                  name="client"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField {...field} select required label="请选择客户" size="small" fullWidth>
                      {businessList &&
                        businessList.map(v => (
                          <MenuItem key={v.pk} value={v.pk}>
                            {v.name}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={() => {
                    const newState = [...openOrderDialog];
                    newState[params.id] = false;
                    setOpenOrderDialog(newState);
                  }}
                >
                  取消
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => {
                    if (isValid) {
                      const newState = [...openOrderDialog];
                      newState[params.id] = false;
                      setOpenOrderDialog(newState);
                    }
                  }}
                >
                  确认
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        </Stack>
      ),
    },
    {
      field: "order",
      headerName: "订单",
      type: "text",
      valueGetter: params => params.row.order?.id,
      // renderCell: params => <Link href={"/shipment/" + params.row.shipment?.id}>{params.row.shipment?.tracking_id}</Link>,
      width: 180,
    },
  ];

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        {/* <Button size="small" variant="contained">
          下单
        </Button> */}
      </GridToolbarContainer>
    );
  };

  return (
    <>
      <Typography variant="h5">{name ? name : "库存明细"}</Typography>
      {/* <ThemeProvider theme={theme}> */}

      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: "dt", sort: "desc" }],
          },
        }}
        filterModel={filterModel}
        onFilterModelChange={newFilterModel => setFilterModel(newFilterModel)}
        components={{ Toolbar: CustomToolbar }}
        autoHeight
        rows={rows}
        columns={columns}
        checkboxSelection={true}
        disableSelectionOnClick={true}
        onSelectionModelChange={newSelectionModel => {
          setSelectionModel(newSelectionModel);
        }}
        selectionModel={selectionModel}
        // onSelectionModelChange={params => setSelected(params)}
        // onCellEditCommit={handleCellEditCommit}
      />
      {/* </ThemeProvider> */}
    </>
  );
};

export default ListInventory;
