import { Divider, Drawer, Grid, List, ListItemButton, Toolbar, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const sidebarWidth = 160;

const Sidebar = props => {
  return (
    <>
      <Drawer
        variant="permanent"
        sx={{
          position: "relative",
          width: sidebarWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: sidebarWidth, boxSizing: "border-box" },
        }}
      >
        <Toolbar variant="dense" />
        <List>
          <ListItemButton component={Link} to="/inventory/add/1">
            <Typography variant="h6">正常进件</Typography>
          </ListItemButton>
          <ListItemButton component={Link} to="/inventory/add/3">
            <Typography variant="h6">返工进件</Typography>
          </ListItemButton>
        </List>
        <Divider />
        <List>
          <ListItemButton component={Link} to="/inventory/list">
            <Typography variant="h6">查看库存</Typography>
          </ListItemButton>
          <ListItemButton component={Link} to="/order/list">
            <Typography variant="h6">查看订单</Typography>
          </ListItemButton>
        </List>
        <Divider />
        <List>
          <ListItemButton component={Link} to="/report/inventory/monthlydetail">
            <Typography variant="h6">库存报表</Typography>
          </ListItemButton>
        </List>
      </Drawer>
    </>
  );
};

export default Sidebar;
